<template>
    <div class="d-flex flex-sm-column flex-md-row flex-wrap my-auto">
        <div class="d-sm-inline-flex d-md-flex form-inline">
            <div class="navy-text mr-2">SORT BY</div>
            <sort-by
                class="f-box mt-1"
                :options="sortBy.options"
                @input="filterChanged('sortBy', $event)"
                ref="sortBy"
            />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2 lg-type">
            <filter-box class="f-box mt-1" 
                :options="tradelocked.options"
                v-model="tradelocked.selected"
                rangeable
                :defaultRangeMin="1"
                :defaultRangeMax="7"
                labelRangePlaceholder="TRADE LOCK"
                @input="filterChanged('tradelocked', $event)"
                />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2">
            <price-ranger 
                @input="filterChanged('price', $event)"
                ref="priceRanger"
            />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="d-sm-inline-flex d-md-flex mx-2">
            <button class="btn btn-primary btn-primary-fancy" style="padding: 5px 5px 5px 5px;" @click="clearFilters(); $event.target.blur();">Clear All</button>
        </div>
    </div>
</template>

<script>
import FilterBox from '../../../components/FilterBox'
import PriceRanger from "@/components/PriceRanger.vue";
import SortBy from '@/components/SortBy'

export default {
    data() {
        return {
            sortBy: {
                selected: "Best Deal",
                options: [
                    { value: "bestdeal", text: 'Best Deal' },
                    { value: "price", text: 'Price', order: true, },
                    { value: "float", text: 'Float', order: true, },
                ]
            },
            tradelocked: {
                selected: { value: null, text: "Status" },
                options: [
                    { value: null, text: "Any" },
                    { value: { min: 0, max: 0 }, text: "Instant Only" },
                    { value: { min: 1, max: 7 }, text: "Trade Locked Only" },
                ]
            }
        }
    },
    components: {
        FilterBox,
        PriceRanger,
        SortBy,
    },
    async mounted() {
        this.clearFilters();

        if (this.$route.query.market_hash_name) {
            console.log("adding")
            await this.$store.dispatch("filters/updateFilter", { filter: "market_hash_name", value: this.$route.query.market_hash_name })
        }
        if (this.$route.query.query) {
            console.log("adding")
            await this.$store.dispatch("filters/updateFilter", { filter: "query", value: this.$route.query.query })
        }
    },
    methods: {
        filterChanged(model, value) {
            // Reset to null if not selected.
            if (!value || !value.value) value = { value: null };
            value = JSON.parse(JSON.stringify(value));

            value = value.value;

            this.$store.dispatch("filters/updateFilter", { filter: model, value });
        },
        async clearFilters(){
            await this.$store.dispatch('filters/setFilterLoadedStatus', false);
            await this.$store.dispatch('filters/clearFilters');

            // Resetting visually
            this.$refs.sortBy.reset();
            this.tradelocked.selected = { value: null, text: "Status" };
            
            this.$store.dispatch('filters/setFilterLoadedStatus', true);
        }
    }
}
</script>
